import React, { useState } from 'react';
import './App.css';
import Results from './ImageSources/Results.jpg';
import PointsByWeek from './ImageSources/PointsByWeek.jpg';
import ResultsByWeek from './ImageSources/ResultsByWeek.jpg';
import HouseRules from './ImageSources/HouseRules.jpg';
import TCLogo from './ImageSources/TCLogo.jpg';
import FinalResults from './ImageSources/FinalResults.jpg';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    // Simple login logic (for demonstration purposes only)
    if (username === 'admin' && password === 'password') {
      setIsLoggedIn(true);
    } else if (username === 'Top Scallop' && (password === 'Bridget' || password === 'Kathleen')) {
      setIsLoggedIn(true);
    } else if (username === 'Los Vinos Tintos' && (password === 'JD' || password === 'Sasha')) {
      setIsLoggedIn(true);
    } else if (username === 'Gouda Vibez' && (password === 'Amanda' || password === 'Kevin' || password === 'Yesabel')) {
      setIsLoggedIn(true);
    } else if (username === 'Balsamic Mar-ga-rette' && (password === 'Margaret' || password === 'Daze')) {
      setIsLoggedIn(true);
    } else if (username === 'Tater Thots' && (password === 'Alex' || password === 'Shelley')) {
      setIsLoggedIn(true);
    } else {
      alert('Incorrect username or password, if it still doesnt work, text Kev.');
    }
  };



  if (isLoggedIn) {
    var welcomename;
    if (password === "JD"){
      welcomename = "Kevin's Bitch"
    } else if (password === "Kathleen"){
      welcomename = "Momma Scotti"
    } else if (password === "Bridget"){
      welcomename = "Scotti"
    } else if (password === "Amanda"){
      welcomename = "Chapin"
    } else if (password === "Yesabel"){
      welcomename = "Babe"
    } else if (password === "Margaret"){
      welcomename = "Frances"
    } else if (password === "Daze"){
      welcomename = "Homie"
    } else if (password === "Alex"){
      welcomename = "Maestro"
    } else if (password === "Sasha"){
      welcomename = "Parcera"
    } else if (password === "Shelly"){
      welcomename = "Mrs. King"
    } else {
      welcomename = password
    }
    return ( 

    <div className="welcome-container">
      <html>
        <body>
          <br></br>
          <br></br>
          <h1>Welcome back, {welcomename}!</h1>
          <p>These are the final results for this season of TC Draft!</p>
          <img src={FinalResults} alt="Final Results" />
          <img src={Results} alt="TC Results" />
          <img src={PointsByWeek} alt="By Week Results" />
          <img src={ResultsByWeek} alt="By Week Results" />
          <img src={HouseRules} alt="By Week Results" />

          <br></br>
          <br></br>
        </body>
      </html>
      
    </div>
    )
  }

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <img src={TCLogo} alt="Bravo TC Logo" />
        <h3>Season 21 Draft</h3>
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Team Name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password (Your First Name)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Log In</button>
      </form>
    </div>
  );
}

export default App;
